/* eslint-disable no-console */
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { RemoveRedEyeOutlined, Splitscreen } from '@mui/icons-material';

import { CarButton } from '../../../CarButton';
import { PatchesName } from '../../../../../../types/types';
import { useLocalization } from '../../../../../../localization';
import { handlerSetDataDelivery } from '../../../../../../graphql/store/helper/js';
import { setDataDeviderState } from '../../../../../../graphql/store/functionsWitingCache';
import { Enum_Mode_Delivery_State, Enum_Order_Status } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { Icon } from '../../../../../../legos';
import { ChangeLocationModal } from '../../ChangeLocationModal';

interface Props {
  index: number;
  orderId: string;
  customerId: string;
  orderNumber: string;
  orderQuantity: number;
  balanceQuantity: number;
  customerLastName: string;
  customerFirstName: string;
  stockAddressOrder: { id: string; address: string; stockId: string }[];
  statusOrder: Enum_Order_Status;
  isWarehouse: boolean;
}

export const TableWarehouseOrdersRowItem: FC<Props> = ({
  orderId,
  customerId,
  orderNumber,
  statusOrder,
  orderQuantity,
  balanceQuantity,
  customerLastName,
  customerFirstName,
  stockAddressOrder,
  isWarehouse,
}) => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();
  const [modalLocalId, setModalLocalId] = useState<null | number>(null);

  const stocks = () => {
    if (stockAddressOrder.length == 0) {
      return <Typography>? / ?</Typography>;
    } else {
      return (
        <Box>
          {stockAddressOrder.map((item, index) => (
            <Stack key={`${item.address}-${index}`} flexDirection="row" alignItems="center" gap={1}>
              <Typography>{item.address}</Typography>
              <IconButton
                onClick={() => {
                  handleOpenModal(index + 1);
                }}
              >
                <Icon icon="edit" />
              </IconButton>
            </Stack>
          ))}
        </Box>
      );
    }
  };

  const handleClickDeviderButton = (): void => {
    setDataDeviderState({
      dataWrite: {
        dataDeviderState: {
          idOrder: orderId,
          isOpenModal: true,
          orderNumber: orderNumber,
        },
      },
    });
  };
  const handleIconButton = () => {
    if (isWarehouse) {
      handlerSetDataDelivery({
        idOrder: orderId,
        isOpenModal: true,
        orderNumber: orderNumber,
        idUser: customerId,
        status: statusOrder,
        callParent: Enum_Mode_Delivery_State.Warehouse,
        isInfoAndPrint: true,
      });
    } else {
      navigate(`/${PatchesName.Orders}/${customerId}/${orderId}`);
    }
  };

  const handleCloseModal = () => {
    setModalLocalId(null);
  };
  const handleOpenModal = (stockId: number) => {
    setModalLocalId(stockId);
  };

  const height = stockAddressOrder.length === 3 ? '90px' : '60px';

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
        {`#${orderNumber}`}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>{`${customerFirstName}${customerLastName}#${customerId}`}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{stocks()}</TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ color: balanceQuantity === orderQuantity ? '#97A408' : '#F44336' }}>
          {balanceQuantity === orderQuantity
            ? `${translateLang('orderCollected')} ${balanceQuantity} / ${orderQuantity}`
            : `${balanceQuantity} / ${orderQuantity}`}
        </Typography>
        <Typography>{translateLang(statusOrder)}</Typography>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ height, borderLeft: 'none', alignItems: 'end' }}>
        <Stack direction="row" spacing={4}>
          <Box>
            {balanceQuantity >= orderQuantity && (
              <CarButton
                statusOrder={statusOrder}
                handleClick={() =>
                  handlerSetDataDelivery({
                    idOrder: orderId,
                    isOpenModal: true,
                    orderNumber: orderNumber,
                    idUser: customerId,
                    status: statusOrder,
                    callParent: Enum_Mode_Delivery_State.Warehouse,
                    isInfoAndPrint: false,
                  })
                }
              />
            )}
          </Box>
        </Stack>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
        <Box bgcolor="#e7eef4" borderRadius={2}>
          <IconButton
            onClick={handleClickDeviderButton}
            disabled={Enum_Order_Status.Awaiting !== statusOrder}
          >
            <Splitscreen
              sx={{
                color: Enum_Order_Status.Awaiting === statusOrder ? '#5269A3' : '',
                transform: 'rotate(90deg)',
              }}
            />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
        <Box bgcolor="#e7eef4" borderRadius={2}>
          <IconButton onClick={handleIconButton}>
            <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
          </IconButton>
        </Box>
      </TableProductActive.CellTable>
      {modalLocalId && (
        <ChangeLocationModal
          productId={stockAddressOrder[modalLocalId - 1]?.stockId}
          isOpen={Boolean(modalLocalId)}
          location={stockAddressOrder[modalLocalId - 1]?.id || ''}
          closeModal={handleCloseModal}
        />
      )}
    </TableProductActive.RowTable>
  );
};
