import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Layout, LayoutTranslate } from '../components/Layout';
import LoginPage from '../pages/Authorization/LoginPage';
import { PrivateRouters } from './PrivateRouters';
import { AdminTranslate } from '../pages/AdminTranslate';
import { NoMatch } from '../pages/NoMatch';
import { PatchesName, RolesEnum } from '../types/types';
import { useHandlerNotificationApp } from '../hooks/useHandlerNotificationApp';
import ClientsList from '../pages/ClientsList';
import ClientInfo from '../pages/ClientInfo';
import OrderList from '../pages/OrdersList';
import OrderInfo from '../pages/OrderInfo';
import CreateOrderPage from '../pages/CreateOrderPage';
import CheckoutPage from '../pages/CheckoutPage';
import FeedbackPage from '../pages/FeedbackPage';
import TopProducts from '../pages/TopProduts';
import AddTopProducts from '../pages/AddTopProduts';
import AddBanner from '../pages/AddBanner';
import WarehousePage from '../pages/Warehouse';
import DeliveryPage from '../pages/Delivery';
import ManagerPanelPage from '../pages/ManagerPanel';
import AccountingPage from '../pages/Accounting';
import { CompleteOrderScanerPage } from '../pages/Delivery/components/OrderHistory/components/CompleteOrderScanerPage/CompleteOrderScanerPage';
import { MobileDeliveryOrderInfo } from '../pages/Delivery/components/MobileDeliveryOrderInfo';
import { ResolveRoleRoute } from './ResolveRoute';
import { useGetPathRedirect } from './hooks/useGetPathRedirect';
import { AdminSettings } from '../pages/AdminSettings';
import { ReturnOrderScanerPage } from '../pages/Delivery/components/OrderHistory/components/ReturnOrderScanerPage/ReturnOrderScanerPage';
import { MobileDeliveryReturnPartInfo } from '../pages/Delivery/components/MobileDeliveryReturnPartInfo';
import { AuthorizationLayout } from '../pages/Authorization/components/AuthorizationLayout';
import { RegisterPage } from '../pages/Authorization/RegisterPage';
import { SuccessRegisterPage } from '../pages/Authorization/SuccessRegisterPage';
import { ConfirmEmailPage } from '../pages/Authorization/ConfirmEmailPage';
import { EnterCodePage } from '../pages/Authorization/EnterCodePage';
import { ForgotPasswordPage } from '../pages/Authorization/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/Authorization/ResetPasswordPage';
import { InvoicePage } from '../pages/Accounting/components/InvoicePage/InvoicePage';
import { SupplierInfo } from '../pages/SupplierInfo/SupplierInfo';

export const AppRouter = () => {
  useHandlerNotificationApp();
  const { pathname } = useLocation();
  const { pathRedirect } = useGetPathRedirect();

  return (
    <Routes>
      <Route path="/" element={<PrivateRouters />}>
        <Route element={<Layout path={pathname} />}>
          <Route path="/" element={<Navigate to={pathRedirect} />} />
          <Route
            path={PatchesName.Clients}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <ClientsList />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`/${PatchesName.Clients}/:idUser`}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <ClientInfo />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.Orders}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <OrderList />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Orders}/:idUser/:idOrder`}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <OrderInfo />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Orders}/${PatchesName.CreateOrder}`}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <CreateOrderPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Orders}/${PatchesName.CreateOrder}/:idUser`}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <CheckoutPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Orders}/${PatchesName.CreateOrder}/:idUser/:idOrder`}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.Accounter,
                ]}
              >
                <CheckoutPage />
              </ResolveRoleRoute>
            }
          />
          {/*<Route
            path={PatchesName.Catalog}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin]}>
                <ChangeCatalog />
              </ResolveRoleRoute>
            }
          />*/}
          <Route
            path={PatchesName.AdminSettings}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin]}>
                <AdminSettings />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Feedback}`}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager]}>
                <FeedbackPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.TopProducts}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager]}>
                <TopProducts />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.TopProducts}/:idCategory`}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager]}>
                <AddTopProducts />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`${PatchesName.Banner}`}
            element={
              <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Manager]}>
                <AddBanner />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.Warehouse}
            element={
              <ResolveRoleRoute
                roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.WarehouseManager]}
              >
                <WarehousePage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.Delivery}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Courier,
                  RolesEnum.WarehouseManager,
                ]}
              >
                <DeliveryPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.ManagerPanel}
            element={
              <ResolveRoleRoute
                roles={[
                  RolesEnum.SuperAdmin,
                  RolesEnum.Admin,
                  RolesEnum.Manager,
                  RolesEnum.WarehouseManager,
                ]}
              >
                <ManagerPanelPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={PatchesName.Accounting}
            element={
              <ResolveRoleRoute
                roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Accounter]}
              >
                <AccountingPage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`/${PatchesName.Accounting}/invoice/:idUser/:idOrder`}
            element={
              <ResolveRoleRoute
                roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Accounter]}
              >
                <InvoicePage />
              </ResolveRoleRoute>
            }
          />
          <Route
            path={`/${PatchesName.Accounting}/providers/:idSupplier`}
            element={
              <ResolveRoleRoute
                roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Accounter]}
              >
                <SupplierInfo />
              </ResolveRoleRoute>
            }
          />
        </Route>
        <Route
          path={PatchesName.MobileDelivery}
          element={
            <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Courier]}>
              <CompleteOrderScanerPage />
            </ResolveRoleRoute>
          }
        />
        <Route
          path={PatchesName.MobileReturnOrder}
          element={
            <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Courier]}>
              <ReturnOrderScanerPage />
            </ResolveRoleRoute>
          }
        />
        <Route
          path={`/${PatchesName.MobileDeliveryOrderInfo}/:idOrder`}
          element={
            <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Courier]}>
              <MobileDeliveryOrderInfo />
            </ResolveRoleRoute>
          }
        />
        <Route
          path={`/${PatchesName.MobileReturnOrder}/:barcode`}
          element={
            <ResolveRoleRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin, RolesEnum.Courier]}>
              <MobileDeliveryReturnPartInfo />
            </ResolveRoleRoute>
          }
        />
        <Route element={<LayoutTranslate />}>
          <Route path={`/${PatchesName.Translate}`} element={<AdminTranslate />} />
        </Route>
      </Route>
      <Route element={<AuthorizationLayout />}>
        <Route path={PatchesName.Login} element={<LoginPage />} />
        <Route path={PatchesName.Register} element={<RegisterPage />} />
        <Route path={`${PatchesName.Register}/:idCategory`} element={<SuccessRegisterPage />} />
        <Route path={PatchesName.EmailConfirmation} element={<ConfirmEmailPage />} />
        <Route path={PatchesName.EnterCode} element={<EnterCodePage />} />
        <Route path={PatchesName.ForgotPassword} element={<ForgotPasswordPage />} />
        <Route path={PatchesName.ResetPassword} element={<ResetPasswordPage />} />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
