import React, { FC, useEffect, useRef } from 'react';
import { Box, Button, Zoom } from '@mui/material';
import { TitlePreDeliveryTable } from './TitlePreDeliveryTable';
import {
  TableCellsValue,
  TableProduct,
} from '../../../../../../components/TableProduct/TableProduct';
import { HeaderCell } from '../../../../../../components/TableProductActive/TableProductActive';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../../../localization';
import { PrintReceipt } from './PrintReceipt';
import { usePreDeliveryStateQuery } from '../../../../../../graphql/cacheQuery/__generated__/preDeliveryState';
import { Enum_Mode_Delivery_State } from '../../../../../../__generated__/types';
import { useGetOrderById } from '../../../../../../graphql/queries/hook/useGetOrderById';
import { SelectItemProductInfo } from '../../../../types';
import { PrintSticker } from '../../../PrintSticker';

interface Props {
  isOpen: boolean;
  onChange(): void;
  loading?: boolean;
  getHeadersTable(): Array<HeaderCell>;
  getRowsTable(): Array<TableCellsValue>;
  isComplectedToDelivery: boolean;
  selectProduct?: SelectItemProductInfo | null;
}
export const PreDeliveryTableComponent: FC<Props> = ({
  isOpen,
  onChange,
  getHeadersTable,
  loading,
  getRowsTable,
  selectProduct,
  isComplectedToDelivery,
}) => {
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const { translateLang } = useLocalization();
  const { deliveryDate, phoneNumbers, orderNumber } = useGetOrderById(
    dataPreDeliveryState?.preDeliveryState.idOrder || ''
  );
  const divReceiptRef = useRef<HTMLDivElement>(null);
  const divStickerRef = useRef<HTMLDivElement>(null);

  const isDeliveryParent =
    dataPreDeliveryState?.preDeliveryState.callParent === Enum_Mode_Delivery_State.Delivery;

  const runReceiptPrint = (isPrintSticker: boolean) => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    ifram.style.fontFamily = 'Arial';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;

    if (pri) {
      pri.document.open();
      if (divReceiptRef.current) {
        pri.document.write(divReceiptRef.current.innerHTML);
      } else if (isPrintSticker && divStickerRef.current) {
        pri.document.write(divStickerRef.current.innerHTML);
      }
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
    }, 500);
  };

  useEffect(() => {
    if (selectProduct) {
      runReceiptPrint(true);
    }
  }, [selectProduct]);

  return (
    <Zoom in={isOpen} mountOnEnter unmountOnExit>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
        mt={5}
      >
        <TitlePreDeliveryTable
          onChange={onChange}
          isComplectedToDelivery={isComplectedToDelivery}
          hideButton={dataPreDeliveryState?.preDeliveryState.isInfoAndPrint}
        />
        <Box width="100%" flex={1} pt={2} boxSizing="border-box" mt={4} mb={3} overflow="auto">
          <TableProduct
            itemsRows={getRowsTable()}
            itemsHeader={getHeadersTable()}
            loading={loading}
          />
        </Box>
        {isComplectedToDelivery && (
          <>
            <Button
              onClick={() => runReceiptPrint(false)}
              variant="contained"
              color="secondary"
              style={{
                width: '300px',
                height: '50px',
                marginRight: '2px',
                borderRadius: '10px',
              }}
            >
              <TranslatedField
                originText={translateLang('printReceipt')}
                fontSize={16}
                isTranslate
                noWrap
                color="white"
                textTransform="none"
              />
            </Button>
            <PrintReceipt ref={divReceiptRef} />
          </>
        )}
        {!isDeliveryParent && selectProduct && (
          <PrintSticker
            ref={divStickerRef}
            customerPhoneNumber={phoneNumbers?.length ? phoneNumbers[0]?.number : ''}
            articleNumber={selectProduct.articleNumber}
            barcode={selectProduct.barcode}
            titleProduct={selectProduct.titleProduct}
            orderId={`#${orderNumber}`}
            orderDate={deliveryDate}
          />
        )}
      </Box>
    </Zoom>
  );
};
