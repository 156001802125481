import { Box, Button, Stack, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { CustomModal } from '../../../../../components';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../../localization';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  UpdateOrderMutationVariables,
  useUpdateOrderMutation,
} from '../../../../../graphql/mutations/__generated__/updateOrder';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
import { format, parse } from 'date-fns';
import { CalendarMUI } from '../../../../../legos';
import { handlerError } from '../../../../../helpers/functions';

interface Props {
  isOpen: boolean;
  date: string;
  orderId: string;
  closeModal(): void;
  isMobile?: boolean;
}

export const DeliveryDateChangeModal: FC<Props> = ({
  closeModal,
  isOpen,
  date,
  orderId,
  isMobile = false,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [runUpdateOrder] = useUpdateOrderMutation();

  const [selectedTime, setSelectedTime] = useState<Date | null>(() => {
    if (date) {
      const initialTimeString = format(new Date(date as string), 'HH:mm');
      return parse(initialTimeString, 'HH:mm', new Date());
    }
    return null;
  });

  const [selectedDate, setsSelectedDate] = useState<Date | null>(() => {
    if (date) {
      const initialDateString = format(new Date(date as string), 'P');
      return parse(initialDateString, 'P', new Date());
    }
    return null;
  });

  const formatedDeliveryDates = (time: Date | null, date: Date | null) => {
    let formattedTime;
    if (time) {
      formattedTime = format(time, 'HH:mm');
    }
    let formattedDate;
    if (date) {
      formattedDate = format(date, 'P');
    }
    return `${formattedDate} ${formattedTime}`;
  };

  const updateOrder = async () => {
    if (selectedTime && selectedDate) {
      const deliveryDate = formatedDeliveryDates(selectedTime, selectedDate);
      const variables: UpdateOrderMutationVariables = {
        id: orderId,
        data: {
          deliveryDate: new Date(deliveryDate).toISOString(),
        },
        locale: updateSelectLanguage as string,
      };
      try {
        const response = await runUpdateOrder({ variables });
        if (response && response.data) {
          addNotification({ messageError: 'Update order success!!!', typeMessage: 'success' });
          closeModal();
        }
      } catch (error: unknown) {
        handlerError(error);
      }
    }
  };

  return (
    <CustomModal
      width={isMobile ? '300px' : '500px'}
      open={isOpen}
      handleClose={closeModal}
      title=""
    >
      <Stack direction="row" spacing={2}>
        <CalendarMUI isLabel={false} value={selectedDate} setValue={setsSelectedDate} />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileTimePicker
            value={selectedTime}
            onChange={newTime => setSelectedTime(newTime)}
            ampm={false}
            inputFormat="HH:mm"
            InputProps={{
              disableUnderline: true,
            }}
            renderInput={params => (
              <TextField
                variant="standard"
                sx={{
                  border: 'none',
                  height: '35px',
                  paddingTop: '10px',
                  paddingLeft: '16px',
                  borderRadius: '6px',
                  paddingRight: '16px',
                  paddingBottom: '5px',
                  background: '#F1F3F8',
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </Stack>

      <Box
        pt={3}
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={updateOrder}
          style={{
            marginTop: '20px',
          }}
        >
          <TranslatedField
            noWrap
            isTranslate
            fontSize={16}
            color="white"
            originText={translateLang('save')}
          />
        </Button>
      </Box>
    </CustomModal>
  );
};
