import React, { FC, useMemo } from 'react';
import { SelectCustom } from '../../../../../../legos';
import { useGetCourier } from '../../../../../../graphql/queries/hook/useGetCourier';
import { useUpdateOrderMutation } from '../../../../../../graphql/mutations/__generated__/updateOrder';
import { useLocalization } from '../../../../../../localization';
import { theme } from '../../../../../../helpers';
type CourierSelectProps = {
  value: string;
  orderId: string;
};

export const CourierSelect: FC<CourierSelectProps> = ({ value, orderId }) => {
  const { couriers } = useGetCourier();
  const [runUpdateOrder] = useUpdateOrderMutation();
  const { updateSelectLanguage, translateLang } = useLocalization();

  const handleChangeCourier = async (value: string) => {
    await runUpdateOrder({
      variables: {
        id: orderId,
        locale: updateSelectLanguage as string,
        data: {
          courier: value === 'null' ? null : value,
        },
      },
    });
  };

  const couriersList = useMemo(() => {
    return [
      { key: 'null', value: `<${translateLang('ChooseCourier')}>` },
      ...(couriers?.map(courier => ({
        key: courier.id || '',
        value: courier.fullName || '',
      })) ?? []),
    ];
  }, [couriers, translateLang]);

  return (
    <SelectCustom
      items={couriersList}
      value={value || 'null'}
      sxContainer={{ width: '100%' }}
      minWidth={100}
      sx={{
        textAlign: 'left',
        ...(!value || value === 'null' ? { color: theme.palette.common.grey } : null),
        '.MuiSelect-select': {
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        },
      }}
      handleChange={e => handleChangeCourier(e.target.value as string)}
    />
  );
};
