import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Icon } from '../../../legos';
import { theme } from '../../../helpers';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { AllOrdersAccountingType } from '../../../graphql/queries/hook/useGetAllOrdersAccounting';

interface CustomerAllNotPaidOrdersAccountingType extends AllOrdersAccountingType {
  checked: boolean;
}

interface InvoiceModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  orderId: string;
  customerAllNotPaidOrdersAccounting: CustomerAllNotPaidOrdersAccountingType[];
  allOrdersAccounting: AllOrdersAccountingType[];
  handleGoToInvoicePage(ids: string[]): Promise<void>;
}

export const InvoiceModal: FC<InvoiceModalProps> = ({
  isModalOpen,
  handleClose,
  orderId,
  allOrdersAccounting,
  customerAllNotPaidOrdersAccounting,
  handleGoToInvoicePage,
}) => {
  const [isCombineModal, setIsCombineModal] = useState(false);

  const [customerNotPaidOrdersAccounting, setIsCustomerNotPaidOrdersAccounting] = useState(
    customerAllNotPaidOrdersAccounting
  );

  const handleCheckboxChange = (index: number) => {
    const newCheckedState = [...customerNotPaidOrdersAccounting];
    newCheckedState[index] = {
      ...newCheckedState[index],
      checked: !newCheckedState[index].checked,
    };
    setIsCustomerNotPaidOrdersAccounting(newCheckedState);
  };

  const toggleCombineModal = () => {
    setIsCombineModal(!isCombineModal);
  };

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: isCombineModal ? 400 : 450,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          {!isCombineModal ? (
            <>
              <TranslatedField
                isTranslate
                variant="h5"
                color={theme.palette.common.darkBlue}
                marginBottom={5}
                originText={'doYouWantCombineOrders'}
              />

              <Stack flexDirection="row" gap={5}>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    height: 50,
                    p: 2,
                    textTransform: 'none',
                    borderRadius: '5px',
                  }}
                  onClick={() => handleGoToInvoicePage([orderId])}
                >
                  <TranslatedField originText={'no'} fontSize={16} isTranslate noWrap />
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 50,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: '#5269A3',
                    borderRadius: '5px',
                  }}
                  onClick={toggleCombineModal}
                >
                  <TranslatedField originText={'yes'} fontSize={16} isTranslate noWrap />
                </Button>
              </Stack>
            </>
          ) : (
            <Stack alignItems="center">
              <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
                <IconButton onClick={toggleCombineModal} sx={{ p: 0 }}>
                  <ArrowBack sx={{ color: theme.palette.primary.main }} />
                </IconButton>
                <IconButton onClick={handleClose} sx={{ p: 0 }}>
                  <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Stack>
              <Typography
                fontSize={30}
                fontWeight={400}
                color={theme.palette.common.darkBlue}
                mb={5}
              >
                {allOrdersAccounting[0].customerInfo}
              </Typography>
              <TableContainer>
                <Table
                  sx={{
                    maxWidth: 405,
                    mb: 5,
                    borderCollapse: 'separate',
                    borderSpacing: '0 7px',
                    borderRadius: '20px',
                  }}
                  aria-label="simple table"
                >
                  <TableBody
                    sx={{
                      '& .MuiTableRow-root td:first-child': {
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                      },
                      '& .MuiTableRow-root td:last-child': {
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                      },
                    }}
                  >
                    {customerNotPaidOrdersAccounting.map((item, index) => (
                      <TableRow
                        key={item.orderId}
                        sx={{
                          border: 0,
                          bgcolor: theme.palette.common.white,
                        }}
                      >
                        <TableCell scope="row" sx={{ p: 0.5 }}>
                          <Checkbox
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </TableCell>
                        <TableCell sx={{ p: 0.5 }}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            justifyContent="center"
                            borderLeft="1px solid #8D8D8D"
                          >
                            {`#${item.orderNumber}`}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: 0.5 }}>
                          {' '}
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            justifyContent="center"
                            borderLeft="1px solid #8D8D8D"
                          >
                            {item.orderData}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: 0.5 }} align="right">
                          <Box
                            display="flex"
                            flexDirection="row"
                            width="100%"
                            justifyContent="center"
                            borderLeft="1px solid #8D8D8D"
                          >
                            {`${item.total} €`}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                variant="contained"
                disabled={!customerNotPaidOrdersAccounting?.some(checked => checked?.checked)}
                fullWidth
                sx={{
                  height: 50,
                  maxWidth: 320,
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '19px',
                  '&:disabled': {
                    color: theme.palette.common.white,
                  },
                }}
                onClick={() =>
                  handleGoToInvoicePage(
                    customerNotPaidOrdersAccounting
                      .filter(item => item.checked)
                      .map(item => item.orderId)
                  )
                }
              >
                <TranslatedField originText={'combine'} fontSize={16} isTranslate noWrap />
              </Button>
            </Stack>
          )}
        </Stack>
      </Fade>
    </Modal>
  );
};
