import React, { FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../../localization';
import { OrderComment } from './OrderComment';
import { useGetOrderById } from '../../../graphql/queries/hook/useGetOrderById';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';

import { UrlParamsObjectType } from '../../../types/types';
import { useGetOrderItems } from '../../../graphql/queries/hook/useGetOrderItems';
import { Enum_Order_Action_Type } from '../../../__generated__/types';
import { useChangeStatusItemOrderMutation } from '../../../graphql/mutations/__generated__/changeStatusItemOrder';
import { handlerError } from '../../../helpers/functions';
import { GetOrderItemsDocument } from '../../../graphql/queries/__generated__/getOrderItems';
import { useHandlerNotificationApp } from '../../../hooks/useHandlerNotificationApp';
import { ToolTypeComment } from '../../../components/ToolTypeComment';
import { OrderItemStatusesCell } from '../../../components/OrderItemStatuses/OrderItemStatusesCell';
import { OrderItemStatusesHeader } from '../../../components/OrderItemStatuses';
import { OrderItemQuantity } from '../../../components';

interface Props {
  orderId: string;
}

export const OrderDetailBox: FC<Props> = ({ orderId }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [runChangeStatusItemOrder] = useChangeStatusItemOrderMutation();
  const { addNotification } = useHandlerNotificationApp();
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const { comment, total } = useGetOrderById(orderId);
  const { orderItems, loading, variablesQuery } = useGetOrderItems(idOrder);

  const formatDate = (item: string | number | Date) => {
    const date = new Date(item);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const orderItemAction = useCallback(
    async (partID: string, actionType: Enum_Order_Action_Type) => {
      if (!partID) {
        return;
      }
      try {
        const response = await runChangeStatusItemOrder({
          variables: {
            input: {
              actionData: {
                orderId: orderId,
                orderItemIds: [partID],
              },
              actionType,
              locale: updateSelectLanguage,
            },
          },
          refetchQueries: [{ query: GetOrderItemsDocument, variables: variablesQuery }],
        });
        if (response.data?.orderItemAction?.length) {
          response.data.orderItemAction.forEach(item => {
            if (item?.success) {
              addNotification({
                messageError: translateLang('statusFixed'),
                typeMessage: 'success',
              });
            } else {
              addNotification({
                messageError: translateLang('somethingWentWrong'),
                typeMessage: 'error',
              });
            }
          });
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    },
    [
      addNotification,
      orderId,
      runChangeStatusItemOrder,
      translateLang,
      updateSelectLanguage,
      variablesQuery,
    ]
  );

  const headersTable = useMemo((): Array<HeaderCell> => {
    return [
      {
        title: '№',
        containerProps: { width: '15px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: <OrderItemStatusesHeader translateLang={translateLang} />,
        containerProps: { color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('article'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productName'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manufacturer'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('provider'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('_delivery'),
        containerProps: { width: '140px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('confirmedTotalQuantity'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('discount'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('productComment'),
        containerProps: { width: '260px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('amount'),
        containerProps: { width: '120px', color: '#f1f3f8', align: 'center' },
      },
    ];
  }, [translateLang]);

  const rowsTable = useMemo((): Array<TableCellsValue> => {
    if (orderItems && orderItems.length) {
      return orderItems.map((item, index) => {
        const values: Array<BoxCell> = [];
        values.push({
          val: index + 1,
          containerProps: { width: '20px', height: '20px', borderLeft: 'none' },
        });
        values.push({
          val: <OrderItemStatusesCell orderItem={item} action={orderItemAction} />,
        });
        values.push({ val: item?.article });
        values.push({ val: item?.productName });
        values.push({ val: item?.brandName });
        values.push({ val: item?.provider });
        values.push({ val: formatDate(item?.deliveryDate as string) });
        values.push({
          val: (
            <OrderItemQuantity
              confirmedQuantity={item.confirmedQuantity ?? 0}
              quantity={item.quantity ?? 0}
            />
          ),
        });
        values.push({ val: item?.discount });
        values.push({ val: <ToolTypeComment comment={item?.comment} width="200px" /> });
        values.push({
          val: `${((item.customerPrice ?? 0) * (item?.quantity || 0)).toFixed(2)} €`,
        });

        return { cellsValues: values };
      });
    }
    return [];
  }, [orderItemAction, orderItems]);

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        mt: '32px',
        borderRadius: '10px',
        padding: '40px',
        mb: '32px',
      }}
    >
      <Typography
        sx={{ lineHeight: '37.65px', fontSize: '30px', fontWeight: '400', color: '#2A3453' }}
      >
        {translateLang('order')}
      </Typography>
      <TableProduct itemsRows={rowsTable} itemsHeader={headersTable} loading={loading} />
      <OrderComment comment={comment} orderAmount={total} />
    </Box>
  );
};
