import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useLocalization } from '../../../../../localization';
import { AllOrdersDeliveryType } from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { CustomTypography } from './CustomTypography';
import { useGetIsMobileQuery } from '../../../../../graphql/cacheQuery/__generated__/getIsMobile';
import { CustomTypographyDesktop } from './CustomTypographyDesktop';
import { formatAddress } from '../../../../../helpers/functions';

export const DetailBlockInfo: FC<AllOrdersDeliveryType> = ({
  customerInfo,
  customerPhone,
  deliveryAddress,
  deliveryTime,
  status,
  orderNumber,
}) => {
  const { translateLang } = useLocalization();
  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;
  const infoData = [
    {
      label: `${translateLang('addresses')}:`,
      value: formatAddress(deliveryAddress),
    },
    {
      label: `${translateLang('deliveryTime')}:`,
      value: format(new Date(deliveryTime as string), 'P HH:mm'),
    },
    {
      label: `${translateLang('client')}:`,
      value: customerInfo,
    },
    {
      label: `${translateLang('phone')}:`,
      value: customerPhone,
    },
    {
      label: `${translateLang('order')}:`,
      value: `#${orderNumber}`,
    },
    {
      label: `${translateLang('status')}:`,
      value: translateLang(`${status}`),
    },
  ];

  return (
    <Stack width="100%" spacing={isMobile ? 2 : 0}>
      {infoData.map(({ label, value }) =>
        isMobile ? (
          <CustomTypography
            key={label}
            label={label}
            value={value}
            divider={false}
            labelColor={'#000000'}
          />
        ) : (
          <CustomTypographyDesktop
            key={label}
            label={label}
            value={value}
            divider={false}
            labelColor={'#2A3453'}
          />
        )
      )}
    </Stack>
  );
};
