import React, { FC, useEffect, useState } from 'react';
import { addDays, format } from 'date-fns';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, SelectChangeEvent, Stack, Typography } from '@mui/material';

import { Icon, SelectCustom } from '../../../../legos';
import { ReturnType } from './hooks/useGetClientReturns';
import { useLocalization } from '../../../../localization';
import { CorrectiveInvoiceForm } from './component/CorrectiveInvoiceForm';
import { useClientReturnStatuses } from './hooks/useClientReturnStatuses';
import { Enum_Customerreturn_Status } from '../../../../__generated__/types';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateCustomerReturnMutation } from '../../../../graphql/mutations/__generated__/updateCustomerReturn';

interface Props {
  clientReturnItem: ReturnType;
  toggleReturnInvoiceTable: (id: string) => void;
}

export const ClientReturnsRowItem: FC<Props> = ({ clientReturnItem, toggleReturnInvoiceTable }) => {
  const { translateLang, selectLanguages } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { clientReturnStatuses } = useClientReturnStatuses();
  const [updateCustomerReturnMutation] = useUpdateCustomerReturnMutation();

  const {
    correctiveInvoiceDate,
    dateReturned,
    correctiveInvoiceNumber,
    id,
    dateOfReceiving,
    status,
    returnReason,
  } = clientReturnItem;

  const initialReturnStatus =
    status === Enum_Customerreturn_Status.Completed
      ? translateLang('formed')
      : translateLang('forming');
  const [returnStatus, setReturnStatus] = useState(initialReturnStatus);

  const [correctiveInvoiceModal, setCorrectiveInvoiceModal] = useState(false);

  useEffect(() => {
    setReturnStatus(initialReturnStatus);
  }, [selectLanguages, initialReturnStatus]);

  const handleChangeReturnStatus = (event: SelectChangeEvent) => {
    const status =
      event.target.value === translateLang('formed')
        ? Enum_Customerreturn_Status.Completed
        : Enum_Customerreturn_Status.Created;

    updateCustomerReturnMutation({
      variables: {
        data: { status },
        id: id as string,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });

        setReturnStatus(event.target.value);
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };
  const toggleCorrectiveInvoiceModal = () => {
    setCorrectiveInvoiceModal(!correctiveInvoiceModal);
  };

  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
          {id}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
          {returnReason}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px', borderLeft: 'none' }}>
          {format(new Date(dateOfReceiving ?? new Date()), 'P')}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px', borderLeft: 'none' }}>
          {format(new Date(dateReturned), 'P')}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px', borderLeft: 'none' }}>
          {format(addDays(new Date(dateOfReceiving ?? new Date()), 14), 'P')}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '100px', borderLeft: 'none' }}>
          <SelectCustom
            disabled={status === Enum_Customerreturn_Status.Completed}
            items={clientReturnStatuses}
            value={returnStatus}
            currentValue={returnStatus}
            handleChange={handleChangeReturnStatus}
            height="40px"
            minWidth={160}
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset"
          />
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ width: '300px', borderLeft: 'none' }}>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            {correctiveInvoiceNumber || correctiveInvoiceDate ? (
              <>
                <Typography fontSize={13}>
                  {`${correctiveInvoiceNumber ?? ''} ${correctiveInvoiceDate ?? ''}`}{' '}
                </Typography>

                {status === Enum_Customerreturn_Status.Created ? (
                  <Box bgcolor="#e7eef4" borderRadius={5}>
                    <IconButton onClick={toggleCorrectiveInvoiceModal}>
                      <Edit fontSize="small" sx={{ color: '#5269A3' }} />
                    </IconButton>
                  </Box>
                ) : null}
              </>
            ) : status === Enum_Customerreturn_Status.Created ? (
              <Button
                variant="outlined"
                startIcon={<Icon icon="add" sx={{ color: '#8D8D8D' }} />}
                sx={{
                  height: 50,
                  p: 2,
                  textTransform: 'none',
                  borderRadius: '10px',
                  border: 'none',
                  '&:hover': {
                    border: 'none',
                  },
                }}
                onClick={toggleCorrectiveInvoiceModal}
              >
                <TranslatedField
                  originText={'addInvoice'}
                  fontSize={13}
                  isTranslate
                  noWrap
                  color="#8D8D8D"
                />
              </Button>
            ) : null}
          </Stack>
        </TableProductActive.CellTable>
        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
          <Box bgcolor="#e7eef4" borderRadius={5}>
            <IconButton onClick={() => toggleReturnInvoiceTable(id as string)}>
              <RemoveRedEyeOutlined fontSize="small" sx={{ color: '#5269A3' }} />
            </IconButton>
          </Box>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      {correctiveInvoiceModal ? (
        <CorrectiveInvoiceForm
          id={id as string}
          isModalOpen={correctiveInvoiceModal}
          handleClose={toggleCorrectiveInvoiceModal}
          invoiceDate={correctiveInvoiceDate}
          invoiceNumber={correctiveInvoiceNumber}
        />
      ) : null}
    </>
  );
};
