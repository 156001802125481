import { useState } from 'react';
import { differenceInCalendarDays, format, subDays } from 'date-fns';

import {
  GetOrdersAccountingQuery,
  useGetOrdersAccountingLazyQuery,
  useGetOrdersAccountingQuery,
} from '../__generated__/getOrdersAccounting';
import { PaginationType } from './useGetOrders';
import { useLocalization } from '../../../localization';
import { handlerError } from '../../../helpers/functions';
import { PAGE_COUNT, PERIOD_FOR_PAYMENT } from '../../../helpers/constants';
import { Enum_Order_Status, OrderFiltersInput } from '../../../__generated__/types';
import { CustomerContactInfoFragment } from '../../fragments/__generated__/customerContactInfo';

type InfoType = NonNullable<NonNullable<CustomerContactInfoFragment['data']>['attributes']>;

type PhoneNumbersType = NonNullable<InfoType>['phoneNumbers'];

export enum Enum_Order_Payment_Status {
  Paid = 'paid',
  NotPaid = 'notPaid',
  PartlyPaid = 'partlyPaid',
  Expired = 'expired',
}

export interface AllOrdersAccountingType {
  orderId: string;
  orderNumber?: string | null;
  orderData: string | null;
  fullPaidDate: string | null;
  billDate: string | null;
  billNumber: number | null;
  customerPhone: PhoneNumbersType;
  customerId: string;
  customerInfo: string;
  total?: number | null;
  paymentTotal?: number | null;
  status?: Enum_Order_Status;
  statusPayment: Enum_Order_Payment_Status;
}
interface ReturnHook {
  allOrdersAccounting: Array<AllOrdersAccountingType>;
  loading: boolean;
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface ReturnHookLazy {
  allOrdersAccounting: Array<AllOrdersAccountingType>;
  loading: boolean;
  getOrdersData: () => Promise<AllOrdersAccountingType[] | undefined>;
}

export interface OrderFilters extends OrderFiltersInput {
  searchNumbers?: string;
  searchStrings?: string;
  paymentTotalType?: string;
}

interface PropsHook {
  filters?: OrderFilters;
  sort?: string;
  filterByCustomerId?: OrderFiltersInput;
  pagination?: PaginationType;
}

interface PropsHookLazy {
  sort?: string;
  filters?: OrderFiltersInput;
  customerId?: string;
}

const FILTER_ALL_STATUSES = [
  {
    status: {
      eq: Enum_Order_Status.Ordered,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Awaiting,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.InStock,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.PickedUp,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Delivery,
    },
  },
  {
    status: {
      eq: Enum_Order_Status.Completed,
    },
  },
];

export const useLazyGetAllOrdersAccounting = ({
  sort = 'date:desc',
  filters,
  customerId,
}: PropsHookLazy): ReturnHookLazy => {
  const { updateSelectLanguage } = useLocalization();
  const [allOrders, setAllOrders] = useState<Array<AllOrdersAccountingType>>([]);
  const [runGetOrders, { loading }] = useGetOrdersAccountingLazyQuery({
    variables: {
      pagination: { limit: -1 },
      filters: filters
        ? {
            ...(filters.date
              ? {
                  date: filters.date,
                }
              : {}),
            ...(filters.status
              ? {
                  status: filters.status,
                }
              : {
                  or: FILTER_ALL_STATUSES,
                }),
            ...(customerId ? { customer: { id: { eq: customerId } } } : {}),
          }
        : {
            date: {
              between: [
                format(subDays(new Date(), 30), 'yyyy-MM-dd'),
                format(new Date(), 'yyyy-MM-dd'),
              ],
            },
            or: FILTER_ALL_STATUSES,
            ...(customerId ? { customer: { id: { eq: customerId } } } : {}),
          },
      sort: [sort],
      locale: updateSelectLanguage as string,
    },
  });
  const getOrdersData = async () => {
    try {
      const response = await runGetOrders();
      if (response.data) {
        const orders = getNormalizedOrders(response.data);
        setAllOrders(orders);
        return orders;
      }
      return [];
    } catch (error: unknown) {
      handlerError(error);
    }
  };

  return {
    allOrdersAccounting: allOrders,
    loading,
    getOrdersData,
  };
};

export const useGetAllOrdersAccounting = ({
  sort = 'date:desc',
  filters,
}: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(1);

  const { data, loading } = useGetOrdersAccountingQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters: filters
        ? {
            ...getSearchFilters(),
            ...(filters.status
              ? {
                  status: filters.status,
                }
              : {
                  or: FILTER_ALL_STATUSES,
                }),
          }
        : {
            date: {
              between: [
                format(subDays(new Date(), 30), 'yyyy-MM-dd'),
                format(new Date(), 'yyyy-MM-dd'),
              ],
            },
            or: FILTER_ALL_STATUSES,
          },
      sort: [sort],
      locale: updateSelectLanguage as string,
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };

  function getSearchFilters() {
    if (filters && (filters?.searchStrings || filters?.searchNumbers)) {
      filters.and?.push({
        or: [
          { id: { containsi: filters?.searchStrings ?? filters?.searchNumbers } },
          { orderNumber: { containsi: filters?.searchStrings ?? filters?.searchNumbers } },

          {
            customer: {
              customer_contact_info: {
                or: [
                  {
                    phoneNumbers: {
                      number: { containsi: filters?.searchNumbers },
                    },
                  },
                  {
                    firstName: { containsi: filters?.searchStrings ?? filters?.searchNumbers },
                  },
                  { lastName: { containsi: filters?.searchStrings ?? filters?.searchNumbers } },
                  {
                    companyName: {
                      containsi: filters?.searchStrings ?? filters?.searchNumbers,
                    },
                  },
                  {
                    user: {
                      username: { containsi: filters?.searchStrings ?? filters?.searchNumbers },
                    },
                  },
                  {
                    user: {
                      id: { containsi: filters?.searchStrings ?? filters?.searchNumbers },
                    },
                  },
                ],
              },
            },
          },
        ],
      });
    }
    delete filters?.searchStrings;
    delete filters?.searchNumbers;

    return filters;
  }

  return {
    allOrdersAccounting: getNormalizedOrders(data),
    loading,
    page,
    pageSize: data?.orders?.meta.pagination.total || 0,
    totalPage: data?.orders?.meta.pagination.total
      ? Math.ceil(data?.orders?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
  };
};

function getStatusPayment(
  total: number,
  paymentTotal: number,
  orderData: string,
  isCompleted: boolean
): Enum_Order_Payment_Status {
  if (
    total > paymentTotal &&
    isCompleted &&
    differenceInCalendarDays(new Date(), new Date(orderData)) > PERIOD_FOR_PAYMENT
  ) {
    return Enum_Order_Payment_Status.Expired;
  }
  if (total === paymentTotal && total > 0) {
    return Enum_Order_Payment_Status.Paid;
  }
  if (paymentTotal && total > paymentTotal) {
    return Enum_Order_Payment_Status.PartlyPaid;
  }
  return Enum_Order_Payment_Status.NotPaid;
}

function getNormalizedOrders(
  data: GetOrdersAccountingQuery | undefined
): Array<AllOrdersAccountingType> {
  const allOrdersAccounting: Array<AllOrdersAccountingType> = [];

  if (data?.orders?.data.length) {
    data.orders.data.forEach(item => {
      let orderNumber = item?.attributes?.orderNumber || '';
      if (item?.attributes?.orderNumberAdd) {
        orderNumber += `-${item.attributes.orderNumberAdd}`;
      }
      allOrdersAccounting.push({
        orderId: item.id || '',
        orderNumber,
        orderData: item.attributes?.date,
        customerId: item.attributes?.customer?.data?.id || '',
        fullPaidDate: item.attributes?.fullPaidDate,
        billDate: item.attributes?.customer_bill?.data?.attributes?.billDate,
        billNumber: item.attributes?.customer_bill?.data?.attributes?.billNumber ?? null,
        customerInfo:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.firstName ||
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.lastName
            ? `${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.firstName} ${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.lastName}\xa0  #${item.attributes?.customer?.data?.id}`
            : item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
                ?.phoneNumbers?.length
            ? `${item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes?.phoneNumbers?.[0]?.number}`
            : `Customer - #${item.attributes?.customer?.data?.id}`,
        customerPhone:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.phoneNumbers,
        total: item.attributes?.total,
        paymentTotal: item.attributes?.paymentTotal || 0,
        status: item.attributes?.status,
        statusPayment: getStatusPayment(
          item.attributes?.total || 0,
          item.attributes?.paymentTotal || 0,
          item.attributes?.date,
          item.attributes?.status === Enum_Order_Status.Completed
        ),
      });
    });
  }

  return allOrdersAccounting;
}
