import React, { FC, useEffect, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { addDays, endOfToday, format, parse, startOfDay, subDays } from 'date-fns';

import { theme } from '../../../../helpers';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { useLocalization } from '../../../../localization';
import { SupplierBalanceTable } from './SupplierBalanceTable';
import { SupplierBalanceToolbar } from './SupplierBalanceToolbar';
import { useGetSupplierBalance } from './hooks/useGetSupplierBalance';
import { SupplierPaymentForm } from './component/SupplierPaymentModal';
import {
  Enum_Supplierpayment_Paymentdirection,
  Enum_Supplierpayment_Paymentmethod,
  SupplierTransactionDocType,
  SupplierTransactionEntity,
  SupplierTransactionsFiltersInput,
} from '../../../../__generated__/types';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { LinkingPaymentModal } from './component/LinkingPaymentModal/LinkingPaymentModal';
import { IncomingInvoicePaymentDetailsModal } from './component/IncomingInvoicePaymentDetailsModal';
import { SupplierPaymentFields } from './component/SupplierPaymentModal/types';
import { ModalConfirm } from '../../../../components/ModalConfirm';
import { useDeleteSupplierPaymentMutation } from '../../../../graphql/mutations/__generated__/deleteSupplierPayment';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';

interface Props {
  idSupplier: string;
  refetchSupplierBalance: () => void;
}

export const SupplierBalance: FC<Props> = ({ idSupplier, refetchSupplierBalance }) => {
  const documentInfo = useRef<{
    id: string;
    type: SupplierTransactionDocType;
    total: number;
    debt: number;
    editMode?: boolean;
    initialValues?: {
      id: string | null;
      [SupplierPaymentFields.Amount]: string;
      [SupplierPaymentFields.Date]: Date;
      [SupplierPaymentFields.Description]: string;
      paymentMethod: Enum_Supplierpayment_Paymentmethod | null;
      paymentDirection: Enum_Supplierpayment_Paymentdirection | null;
    };
  } | null>(null);
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { page, changePage, selDateTo, selDateFrom, changeDate, resetFilters } =
    useFiltersSearchParamsForOrders({
      dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
    });

  const [transactionModal, setTransactionModal] = useState(false);
  const [linkingPaymentModal, setLinkingPaymentModal] = useState(false);
  const [invoicePaymentDetails, setInvoicePaymentDetails] = useState(false);
  const { transactions, pagination, isLoading, supplierTransactionRefetch } = useGetSupplierBalance(
    {
      pagination: {
        page,
        pageSize: PAGE_COUNT,
      },
      filters: getFiltersInvoices(),
    }
  );

  const [runDeleteSupplierReturn] = useDeleteSupplierPaymentMutation();

  const handleDeleteSupplierPayment = () => {
    if (documentInfo.current?.id) {
      runDeleteSupplierReturn({
        variables: {
          id: documentInfo.current?.id,
        },
      })
        .then(() => {
          addNotification({
            messageError: translateLang('paymentSuccessfullyDeleted'),
            typeMessage: 'success',
          });
          refresh();
        })
        .catch(error => {
          addNotification({
            messageError: error.message,
            typeMessage: 'error',
          });
        });
    }
  };

  const action = (transaction: SupplierTransactionEntity, type: 'edit' | 'open' | 'delete') => {
    if (type === 'edit') {
      documentInfo.current = {
        id: transaction.docId,
        type: transaction.docType!,
        total: transaction.docAmount ?? 0,
        debt: 0,
        editMode: true,
        initialValues: {
          id: transaction.docId,
          amount: `${transaction.docAmount ?? ''}`,
          date: parse(transaction.docDate, 'yyyy-MM-dd', new Date()),
          description: transaction.comment ?? '',
          paymentDirection:
            transaction.docType === SupplierTransactionDocType.IncomingPaymentCash ||
            transaction.docType === SupplierTransactionDocType.IncomingPaymentNonCash
              ? Enum_Supplierpayment_Paymentdirection.Incoming
              : Enum_Supplierpayment_Paymentdirection.Outgoing,
          paymentMethod:
            transaction.docType === SupplierTransactionDocType.IncomingPaymentCash ||
            transaction.docType === SupplierTransactionDocType.OutgoingPaymentCash
              ? Enum_Supplierpayment_Paymentmethod.Cash
              : Enum_Supplierpayment_Paymentmethod.NonCash,
        },
      };
      setTransactionModal(true);
    } else if (type === 'open') {
      if (
        transaction?.docType === SupplierTransactionDocType.IncomingPaymentCash ||
        transaction?.docType === SupplierTransactionDocType.IncomingPaymentNonCash ||
        transaction?.docType === SupplierTransactionDocType.OutgoingPaymentCash ||
        transaction?.docType === SupplierTransactionDocType.OutgoingPaymentNonCash ||
        transaction?.docType === SupplierTransactionDocType.Return
      ) {
        documentInfo.current = {
          id: transaction.docId,
          type: transaction.docType,
          total: 0,
          debt: 0,
        };
        setLinkingPaymentModal(true);
      }
      if (transaction?.docType === SupplierTransactionDocType.IncomingInvoice) {
        documentInfo.current = {
          id: transaction.docId,
          type: transaction.docType,
          total: transaction.docAmount ?? 0,
          debt: transaction.debtAmount ?? 0,
        };
        setInvoicePaymentDetails(true);
      }
    } else if (type === 'delete') {
      documentInfo.current = {
        id: transaction.docId,
        debt: 0,
        total: 0,
        type: transaction.docType!,
      };
      setShowConfirmModal(true);
    }
  };

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  useEffect(() => {
    resetFilters();
  }, []);

  const refresh = async () => Promise.all([supplierTransactionRefetch(), refetchSupplierBalance()]);

  const toggleTransactionModal = () => {
    setTransactionModal(!transactionModal);
  };

  const openTransactionModal = () => {
    documentInfo.current = {
      id: '',
      type: SupplierTransactionDocType.OutgoingPaymentNonCash,
      total: 0,
      debt: 0,
      editMode: false,
      initialValues: {
        id: null,
        amount: '',
        date: new Date(),
        description: '',
        paymentDirection: null,
        paymentMethod: null,
      },
    };
    setTransactionModal(true);
  };

  const toggleLinkingPaymentModal = async (isStateChanged: boolean) => {
    if (linkingPaymentModal) {
      documentInfo.current = null;
      if (isStateChanged) {
        await refresh();
      }
    }
    setLinkingPaymentModal(!linkingPaymentModal);
  };

  const toggleInvoicePaymentDetails = () => {
    setInvoicePaymentDetails(!invoicePaymentDetails);
  };

  function getFiltersInvoices(): SupplierTransactionsFiltersInput {
    const filters: SupplierTransactionsFiltersInput = {
      supplierId: idSupplier,
      dateStart: format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
      dateEnd: format(endOfToday(), 'yyyy-MM-dd'),
    };

    if (selDateTo && selDateFrom) {
      filters['dateStart'] = selDateFrom;
      filters['dateEnd'] = selDateTo;
    }
    return filters;
  }

  return (
    <Box width="100%">
      <SupplierBalanceToolbar />
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3.75}
        pr={2}
        pb={2}
        bgcolor={theme.palette.common.white}
        sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <TranslatedField
          isTranslate
          variant="h5"
          fontSize={26}
          color="black"
          originText={translateLang('transactions')}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
          }}
          onClick={openTransactionModal}
        >
          <TranslatedField originText={'createPayment'} fontSize={16} isTranslate noWrap />
        </Button>
      </Stack>

      <SupplierBalanceTable
        transactions={transactions}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={page}
        loading={isLoading}
        action={action}
      />
      {showConfirmModal ? (
        <ModalConfirm
          closeModal={() => setShowConfirmModal(false)}
          openModal
          title="Delete payment"
          handlerConfirm={() => {
            handleDeleteSupplierPayment();
          }}
        />
      ) : null}
      {transactionModal ? (
        <SupplierPaymentForm
          supplierId={idSupplier}
          isModalOpen={transactionModal}
          handleClose={toggleTransactionModal}
          refresh={refresh}
          editMode={documentInfo.current?.editMode}
          initialValues={documentInfo.current?.initialValues}
        />
      ) : null}
      {linkingPaymentModal && documentInfo.current ? (
        <LinkingPaymentModal
          documentType={documentInfo.current.type}
          documentId={documentInfo.current.id}
          supplierId={idSupplier}
          isModalOpen={linkingPaymentModal}
          handleClose={toggleLinkingPaymentModal}
        />
      ) : null}
      {invoicePaymentDetails && documentInfo.current?.id ? (
        <IncomingInvoicePaymentDetailsModal
          total={documentInfo.current.total}
          debt={documentInfo.current.debt}
          invoiceId={documentInfo.current?.id}
          handleClose={toggleInvoicePaymentDetails}
          isModalOpen
        />
      ) : null}
    </Box>
  );
};
