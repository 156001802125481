import React, { FC } from 'react';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { FormControl, Box, InputLabel, MenuItem, Typography, SxProps, Theme } from '@mui/material';

import { theme } from '../helpers';
import { useLocalization } from '../localization';
import styled from '@emotion/styled';

interface Props extends SelectProps {
  error?: boolean;
  disabled?: boolean;
  label?: string;
  value?: string;
  height?: string;
  boxShadow?: string;
  items: string[] | { key: string; value: string }[];
  minWidth?: number;
  currentValue?: string;
  helperText?: string | false;
  handleChange?: (event: SelectChangeEvent) => void;
  sxContainer?: SxProps<Theme> | undefined;
}

export const SelectCustom: FC<Props> = ({
  items,
  value,
  label,
  height,
  boxShadow,
  minWidth,
  handleChange,
  disabled,
  error,
  helperText,
  sx,
  sxContainer,
}) => {
  const { translateLang } = useLocalization();
  return (
    <Box sx={{ ...sxContainer, minWidth: { minWidth } }}>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          label={label}
          onChange={handleChange}
          sx={{
            height: { height },
            boxShadow: boxShadow,
            backgroundColor:
              value === translateLang('formed') ? theme.palette.common.appleGreen : undefined,
            ...sx,
          }}
          error={error}
        >
          {items.map(item => (
            <MenuItem
              key={typeof item === 'string' ? item : item.key}
              value={typeof item === 'string' ? item : item.key}
            >
              {typeof item === 'string' ? item : item.value}
            </MenuItem>
          ))}
        </Select>
        {error ? (
          <Typography fontSize={12} color="#D32F32" mt="3px" textAlign="start">
            {helperText}
          </Typography>
        ) : null}
      </FormControl>
    </Box>
  );
};
