import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { theme } from '../helpers';

type OrderItemQuantityProps = {
  confirmedQuantity: number;
  quantity: number;
};

export const OrderItemQuantity: FC<OrderItemQuantityProps> = ({ confirmedQuantity, quantity }) => {
  return (
    <Typography
      fontSize={14}
      component="span"
      color={
        confirmedQuantity === quantity ? theme.palette.common.appleGreen : theme.palette.error.light
      }
    >
      {confirmedQuantity} / {quantity}
    </Typography>
  );
};
